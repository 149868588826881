.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImage {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin: auto;
  display: block;
  object-fit: cover;
}

.name {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.bio {
  font-size: 16px;
  color: gray;
  margin-bottom: 1rem;
}

.socialMediaButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
} 